import React from "react";

export default function PostCard(props) {
  return (
    <div className="post-card container-fluid mb-5">
      <div className="row px-3 py-4">
        <div className="col-md-4 d-flex align-items-center">
          <div className="width-100">
            <a
              href={props.path ? props.path : props.url}
              target={props.path ? "_self" : "_blank"}
              rel="noreferrer"
            >
              {props.image ? (
                <img
                  src={props.image}
                  className="card-image-top img-fluid"
                  alt={props.altText}
                />
              ) : (
                <img
                  src="/blog.png"
                  className="card-image-top img-fluid"
                  alt={props.altText}
                />
              )}
            </a>
          </div>
        </div>
        <div className="col">
          <div className="card-body">
            <h5>
              <span className="card-text muted">{props.date}</span>
            </h5>
            <h4 className="card-title">
              <a
                href={props.path ? props.path : props.url}
                target={props.path ? "_self" : "_blank"}
                rel="noreferrer"
              >
                {props.title}
              </a>
            </h4>
            <p className="card-text">{props.intro}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

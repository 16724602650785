import React from "react";
import Layout from "../layouts/page";
import PostCard from "../components/post-card";
import data from "../../data/posts.json";

const { posts } = data;

export default function Posts() {
  const postItems = [...posts]
    .reverse()
    .filter(post => {
      return post.state !== "draft";
    })
    .map((post, i) => {
      return (
        <PostCard
          path={post.path}
          key={"post-" + i}
          url={post.url}
          date={post.date}
          title={post.title}
          image={post.image}
          intro={post.intro}
          slug={post.slug}
        />
      );
    });

  return (
    <Layout path="/posts">
      <div className="row">
        <div className="col-12 p-5">
          <img src="/posts.png" className="img-header" alt="posts" />
        </div>
      </div>
      <div className="row">
        <div className="col-12 px-5">{postItems}</div>
      </div>
    </Layout>
  );
}
